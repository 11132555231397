<template>
  <div>
      <div style="display:flex">
          <div style="display:flex;font-size:16px;font-weight:bold;height:35px;line-height:35px">
              <div style="margin-right:20px" v-for="(item,index) in titleData" :key="index">{{item.dataName}}：<span style="color:red">{{item.dataValue}}</span></div>
          </div>
          <Button style="margin-left:auto;margin-bottom:20px" type="primary" @click="importExcel">导出</Button>
      </div>
      <Table
      border
      :columns="tableColumns"
      :data="tableData"
      style="height:calc(100%-100px)"
    ></Table>
  </div>
</template>

<script>
  import LiefengTable from "@/components/LiefengTable";
  export default {
    props:{
      infoIdLis:String,
      isTrue:0},
      
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengTable},
    data() {
      //这里存放数据
      return {
          tableData:[],
          tableColumns:[],
          titleData:[]
      }
    },
    //方法集合
    methods: {
        // 导出按钮
        importExcel(){
            this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？</p>",
        onOk: () => {
          let tHeader = [
            "社区名称",
            "阅读次数",
            "阅读人数",
            "点赞次数",
            "点赞人数",
            "分享次数",
            "分享人数",
            '参与次数',
            '参与人数'
          ];
          let filterVal = [
            "orgName",
            "阅读次数",
            "阅读人数",
            "点赞次数",
            "点赞人数",
            "分享次数",
            "分享人数",
            '参与次数',
            '参与人数'
          ];
          let tableData = JSON.parse(JSON.stringify(this.tableData));
          this.$core.exportExcel(tHeader, filterVal, tableData, "行为数据分析列表");
        },
      });
        },
        getData(){
            let List = []
            this.$get('/old/api/pc/sy/stat/statMsgBrowse',{
                orgLevel:5,
                // infoIdList:'8a9dc42a773a39c101773cc787130da0'
                infoIdList:this.infoIdLis
            }).then(res=>{
                if(res.code == 200){
                this.titleData = []
                let data = res.data
                this.titleData = data.items    
                    if(data.dataList.length !=0){
                          this.tableData = []
                          this.tableColumns=[
                            {
                                title: '社区名称',
                                    key: 'orgName',
                                    minWidth: 100,
                                    align: "center"   
                            }
                        ]
                        console.log('进来了');                 
                        data.items.map(item=>{
                        this.tableColumns.push({                   
                            title: item.dataName,
                            key: item.dataName,
                            minWidth: 100,
                            align: "center"         
                        })
                    })
                    data.dataList.map(item => {
                        let obj = {
                            orgName:''
                        };
                        item.items.map(items => {
                            
                            obj[items.dataName] = items.dataValue;
                            obj.orgName = item.orgName
                        })
                        this.tableData.push(obj)
                        
                        
                    })
                    console.log(this.tableData);
                }else{
                  this.tableData = []
                  this.tableColumns=[]
                  this.titleData = []
                }
                
                }else{
                    this.$Message.error({
                        content:'获取数据失败',
                        background:true
                    })
                    return
                }
                
            })
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
       
    },
    watch:{
      isTrue:{
        handler(val,newVal){
          if(val){
            console.log(val);
            
            this.getData()
          }
        }
      }
        // infoIdLis:{
        //     handler(val,newVal){
        //         if(val){
        //             this.getData()
        //         }
        //     }
        // }
    }
  }
</script>
<style lang='less' scoped>
  //编写css
</style>